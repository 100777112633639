// DepositWithdrawDialog.tsx

import React, { useState, useEffect } from "react";
import classNames from "classnames";
// import { TextField } from "@mui/material";
import { Button } from "../components/Button/Button";
import { MarginCurrency } from "../interfaces/marginCurrency.interface"; // Adjust the import path as necessary
import { Params } from "../interfaces/params.interface"; // Adjust the import path as necessary
import { ParamsUser } from "../interfaces/paramsUser.interface"; // Adjust the import path as necessary
import { Wallet } from "../interfaces/wallet.interface"; 
import { Fee } from "../interfaces/fee.interface"; 
import { ethers } from "ethers";
import { eventCloud } from "../EventCloud";
import { handleWithdraw } from "../solidity/Withdraw"; // Import the withdrawal function
import WithdrawalConfirmDialog from "./WithdrawalConfirmDialog"; 
import { handleDepositETH } from "../solidity/DepositETH"; // Import the withdrawal function
import { handleDepositERC20 } from "../solidity/DepositERC20"; // Import the withdrawal function
import { Modal } from "../components/Modal/Modal";
import { ModalHeader } from "../components/Modal/ModalHeader";

import { Controller, useForm } from "react-hook-form";
import { z } from "zod";
import { pipe } from "fp-ts/lib/function";
import { zodResolver } from "@hookform/resolvers/zod";

import { TextField } from "../components/TextField/TextField";
import { Balance } from "../interfaces/balance.interface";

import { DocumentDuplicateIcon } from "@heroicons/react/24/solid";

import { calculateTxFee, getGasPrice } from "../helpers/fee"

import QRCode from 'qrcode.react';
import { CopyField } from "../components/CopyField/CopyField";

interface DepositWithdrawDialogProps {
  open: boolean;
  action: string;
  walletBalance: string;
  selectedMarginCurrency: MarginCurrency;
  selectedWallet: Wallet;
  balances: Record<string, Balance>;
  onClose: () => void;
}

const useFormSchema = (direction: string) =>
  z.object({
    amount: z.string().min(1, "Required"),
    destinationAddress:
      direction === "withdraw"
        ? z.string().min(1, "Required")
        : z.string().optional(),
  });

type FormSchema = z.infer<ReturnType<typeof useFormSchema>>;

const DepositWithdrawDialog: React.FC<DepositWithdrawDialogProps> = ({
  open,
  action,
  onClose,
  walletBalance,
  selectedWallet,
  selectedMarginCurrency,
  balances
}) => {
  const [direction, setDirection] = useState(action);
  const [params, setParams] = useState<Params | null>(null);
  const [paramsUser, setParamsUser] = useState<ParamsUser | null>(null);
  const [isApprovalPending, setApprovalPending] = useState(false);
  const [isDepositPending, setDepositPending] = useState(false);
  const [isWaitingConfirmation, setWaitingConfirmation] = useState(false);
  const [isWithdrawalConfirmDialogOpen, setIsWithdrawalConfirmDialogOpen] = useState(false);
  const [pendingTxHash, setPendingTxHash] = useState("");

  useEffect(() => {
    // Subscribe
    const subscription = eventCloud.paramsStore.subscribe((params) => {
      setParams(params);
    });

    return () => {
        subscription.unsubscribe();
     };

  }, []);

  useEffect(() => {
    // Subscribe
    const subscription = eventCloud.paramsUserStore.subscribe((user_params) => {
      console.log("PARAMS USER UPDATE", user_params);
      if (!user_params) return;
      setParamsUser(user_params);

      if (user_params?.eth_deposit_address == "") {
        eventCloud.requestEthAddress()
      }
    });

    return () => {
        subscription.unsubscribe();
     };

  }, []);

  const {
    register,
    handleSubmit,
    setError,
    setValue,
    formState: { errors, isSubmitting },
    control,
  } = useForm<FormSchema>({
    resolver: zodResolver(useFormSchema(direction)),
  });

  if (!balances) return null;

  const [formError, setFormErrorMessage] = useState<string | undefined>();

  const [amount, setAmount] = useState("");
  const [destinationAddress, setDestinationAddress] = useState("");

  let GAS_DEPOSIT_ETH = 25000;
  let GAS_WITHDRAW_ETH = 60000;
  let GAS_APPROVE = 60000;
  let GAS_DEPOSIT_TOKEN = 100000;
  let GAS_TRANSFER_TOKEN = 60000;

    switch (selectedMarginCurrency.network) {
        case "Avalanche":
            GAS_DEPOSIT_TOKEN = 90000;
            break;

        case "Arbitrum":
            GAS_DEPOSIT_ETH = 500000;
            GAS_APPROVE = 2000000;
            GAS_DEPOSIT_TOKEN = 2000000;  
            GAS_TRANSFER_TOKEN = 2200000;
            GAS_WITHDRAW_ETH = 1000000;
            break;
    }


  const initiateWithdraw = async (
    amount: string,
    destinationAddress: string
  ) => {
    console.log(
      `[initiateWithdraw] amount=${amount} destinationAddress=${destinationAddress}`
    );

    const baseToken = eventCloud.selectedMarginCurrency;

    // Define the parameters for the withdrawal
    const token = selectedMarginCurrency.token_address; // Replace with actual token address
    const nonce = Date.now(); // Unique nonce for each transaction
    const feeWithdrawal = getWithdrawlaFee(); 
    const contractAddress = eventCloud.params.base_contract;

    try {
      const withdrawalDetails = await handleWithdraw({
        token,
        amount,
        destinationAddress,
        nonce,
        feeWithdrawal,
        contractAddress,
      });

      onClose();
    } catch (error) {
      console.error("Withdrawal failed:", error);
    }
  };

  const initiateDeposit = async (amount: string) => {
    console.log(`[initiateDeposit] amount=${amount} token_contract_address=${selectedMarginCurrency.token_contract_address}`);
    if (!params) return;
    
    const gasPrice = getGasPrice(selectedMarginCurrency, params);
    if (!gasPrice) return;
    try {
      if (selectedMarginCurrency.token_contract_address == "0x0000000000000000000000000000000000000000") {
        const depositDetails = await handleDepositETH({
          wallet: selectedWallet,
          marginCurrency: selectedMarginCurrency,
          amount,
          recommendedGasPrice: getGasPrice(selectedMarginCurrency, params),
          setDepositPending,
          depositGasLimit: GAS_DEPOSIT_ETH,
          onClose,
          setWaitingConfirmation,
          setPendingTxHash,
        });
      } else {
        const depositDetails = await handleDepositERC20({
          wallet: selectedWallet,
          marginCurrency: selectedMarginCurrency,
          amount,
          recommendedGasPrice: getGasPrice(selectedMarginCurrency, params),          
          setApprovalPending,
          setDepositPending,
          approveGasLimit: GAS_APPROVE,
          depositGasLimit: GAS_DEPOSIT_TOKEN,
          onClose,
          setWaitingConfirmation,
          setPendingTxHash,
        });
      }
    } catch (error) {
      console.error("Withdrawal failed:", error);
    }
  };

  const handleDepositMax = () => {       
    const fee = calcGasFee();
    if (!selectedMarginCurrency || !fee) return; 
    let gas = fee.token_fee;
    

    const amount = formatWalletAmount(Number(walletBalance), selectedMarginCurrency.decimals, selectedMarginCurrency.token_decimals);
    let max = Number(amount)

    if (selectedMarginCurrency.token_address == selectedMarginCurrency.native_token) {
      max = max - Number(gas);
    }     

    if (max < 0) {
      max = 0;
    }

    const formattedMax = max.toFixed(selectedMarginCurrency.decimals);
    console.log(`Deposit max gas=${gas} amount=${amount} max=${max} formattedMax=${formattedMax}`);

    setValue('amount', formattedMax); 
  }

  const handleWithdrawMax = () => {
    console.log(`[handleWithdrawMax] balances`, balances);

    if (!selectedMarginCurrency) return;  
    const formattedMax = formatAmount(!balances[selectedMarginCurrency?.token_address] 
      ? 0 
      : Number(balances[selectedMarginCurrency?.token_address]?.available_balance) -
        Number(balances[selectedMarginCurrency?.token_address]?.crossmargin_reserve)
      , 
      selectedMarginCurrency.decimals
    );

    setValue('amount', formattedMax); 
  }

  const getNativeCurrency = (token_address: string): MarginCurrency => {
    var curr = eventCloud.findMarginCurrency(token_address);
    //console.log("[getNativeCurrency] ",token_address, curr)
    return curr;
  } 

  if (!params) return null;

  const calcGasFee = (): Fee => {
      let fee = calculateTxFee(selectedMarginCurrency, params, direction);
      //console.log("FEE: ", fee);
      return fee;
  }

  const openKeyDialog = () => {
    eventCloud.emit("openKeyDialog", selectedWallet);
    onClose();
  }


    const handleCloseWithdrawalConfirmDialog = () => {
        console.log("[handleCloseWithdrawalConfirmDialog]");
        setIsWithdrawalConfirmDialogOpen(false);
    }

    const handleConfirmWithdrawalConfirmDialog = () => {
        initiateWithdraw(amount, destinationAddress)
    }

    const getWithdrawlaFee = (): string => {
        return (Number(calcGasFee().token_fee)*1e18).toFixed(0); 
    }

    const handleConnectMetaMask = () => {
        eventCloud.emit("connectMetaMask", {});
    }

  return (
    <div>
      <form
        onSubmit={handleSubmit((data) => {
          console.log("Handle submit", data);
          console.log("direction", direction);
          if (direction === "withdraw") {
            //initiateWithdraw(data.amount, data.destinationAddress!);
            setAmount(data.amount);
            setDestinationAddress(data.destinationAddress!);
            setIsWithdrawalConfirmDialogOpen(true);
          } else {
            initiateDeposit(data.amount);
          }
        })}
        className="relative"
      >
        <div className="sm:-mt-5 -mt-1">
          <ModalHeader onClose={onClose}>{direction == "deposit" ? "Deposit" : "Withdraw"} {selectedMarginCurrency.symbol}</ModalHeader>
        </div>

        <div className="flex pt-1 pb-4">
          <label className="w-1/2">
            <input
              type="radio"
              name="direction"
              value="Deposit"
              disabled={isApprovalPending || isDepositPending || isWaitingConfirmation}
              checked={direction === "deposit"}
              onChange={() => setDirection("deposit")}
              className="invisible absolute"
            />
            <div
              className={classNames(
                "w-full h-10 flex items-center justify-center cursor-pointer box-border text-center px-4 py-2 text-xs text-gray-150 hover:text-white disabled:text-gray-450 bg-gray-750 hover:bg-gray-740 border border-gray-650",
                { "border-b-2 border-b-white": direction === "deposit" }
              )}
            >
              DEPOSIT
            </div>
          </label>

          <label className="w-1/2 -ml-px">
            <input
              type="radio"
              id="limit"
              name="direction"
              value="Withdraw"
              disabled={isApprovalPending || isDepositPending || isWaitingConfirmation}
              checked={direction === "withdraw"}
              onChange={() => setDirection("withdraw")}
              className="invisible absolute"
            />
            <div
              className={classNames(
                "w-full h-10 flex items-center justify-center cursor-pointer box-border text-center px-4 py-2 text-xs text-gray-150 hover:text-white disabled:text-gray-450 bg-gray-750 hover:bg-gray-740 border border-gray-650",
                { "border-b-2 border-b-white": direction === "withdraw" }
              )}
            >
              WITHDRAW
            </div>
          </label>
        </div>
        {direction == "deposit" && (
          <>
            <div>
              {selectedWallet.isMetaMask && (
                <>                  
                  <p className="text-xs text-gray-450 pb-3">
                    METAMASK BALANCE: <strong className="text-gray-150">{formatWalletAmount(Number(walletBalance), selectedMarginCurrency.decimals, selectedMarginCurrency.token_decimals)}{" "}
                    {selectedMarginCurrency.symbol}
                    {" "}
                    [{selectedMarginCurrency.network_slug}]
                    </strong> 

                    <Button
                      type="button"
                      variant="gray"
                      size="small"
                      disabled={isApprovalPending || isDepositPending || isWaitingConfirmation}
                      onClick={handleDepositMax}
                      className="ml-2"
                    >
                      MAX
                    </Button>
                  </p>
                </>
              )}
            </div>
          </>
        )}

        {direction == "withdraw" && (
          <p className="text-xs text-gray-450 pb-3">
            Available: <strong className="text-gray-150">{
              formatAmount(!balances[selectedMarginCurrency?.token_address] 
                ? 0 
                : Number(balances[selectedMarginCurrency?.token_address]?.available_balance) -
                  Number(balances[selectedMarginCurrency?.token_address]?.crossmargin_reserve)
                , 
                selectedMarginCurrency.decimals
              )}
              {" "}
              {selectedMarginCurrency.symbol}
              {" "}
              [{selectedMarginCurrency.network_slug}]
            </strong>

            <Button
              type="button"
              variant="gray"
              size="small"
              onClick={handleWithdrawMax}
              className="ml-2"
            >
              MAX
            </Button>
          </p>
        )}

        {selectedWallet.isMetaMask ? (
          <div className="w-full mb-5">
            <TextField
              {...register("amount")}
              error={errors?.amount}
              label={"Amount"}
              disabled={isApprovalPending || isDepositPending || isWaitingConfirmation}
              required
            />
          </div>
        ) : (
          <>
          {direction == "deposit" && (
            <>
              <div className="w-full flex justify-center mt-2 mb-4">
                <div className="bg-white">
                  <QRCode value={paramsUser?.eth_deposit_address || ""} size={200}  level={"Q"} />
                </div>
              </div>

              <div>
                <CopyField 
                  courier
                  text={paramsUser?.eth_deposit_address || ""}
                  label={"Deposit Address"}
                />
              </div>

              <div className="mt-4 text-xxs">
                THIS IS YOUR DEPOSIT ADDRESS. ALL <span className="text-yellow-550">{selectedMarginCurrency.symbol}
              {" "}
              [{selectedMarginCurrency.network_slug}]</span> SENT TO THIS ADDRESS WILL BE ADDED TO YOUR BALANCE.
              </div>
            </>
            )}
          </>
        )}

        {direction == "withdraw" && (

          <div className="w-full mb-5">
            <TextField
              {...register("destinationAddress")}
              error={errors?.destinationAddress}
              label={"Destination Address"}
              required
              courier
              self={selectedWallet.address}
              setValue={(text) => {
                setValue("destinationAddress", text);
              }}
            />
          </div>
        )}


        {(direction == "withdraw" || selectedWallet.isMetaMask) && (
          <div className="py-1">
            <div className="flex text-xxs pb-1">
              <div className="text-gray-450">GAS Price:</div>
              <div className="ml-auto text-gray-150">{formatGasPrice(getGasPrice(selectedMarginCurrency, params))} GWEI</div>
            </div>

            <div className="flex text-xxs pb-1">
              <div className="text-gray-450">GAS Fee:</div>
              <div className="ml-auto text-gray-150">
                {direction == "withdraw" 
                  ? formatGasFee(calcGasFee().token_fee, Math.min(8, selectedMarginCurrency.token_decimals)) +" "+selectedMarginCurrency.symbol+" ["+selectedMarginCurrency.network+"]"
                  : formatGasFee(calcGasFee().native_fee, Math.min(8, Number(getNativeCurrency(selectedMarginCurrency.native_token).token_decimals))) +" "+getNativeCurrency(selectedMarginCurrency.native_token).symbol+" ["+getNativeCurrency(selectedMarginCurrency.native_token).network+"]"
                }</div>
            </div>
            <div className="flex text-xxs pb-1">
              <div className="text-gray-450">GAS Fee USD:</div>
              <div className="ml-auto text-gray-150">
                {formatGasFee(calcGasFee().usd_fee, Math.min(8, selectedMarginCurrency.token_decimals)) +" USD"}
              </div>
            </div>
          </div>
        )}

        {direction == "deposit" && !selectedWallet.isMetaMask && (
            <div className="mt-4 p-1 text-xs text-red-550">
                Make sure to back up your private key. If you lose your private key, you will not be able to access your funds.
                <Button
                  className="ml-2"
                  variant="gray"
                  size="small"
                  onClick={openKeyDialog}
                >
                  CLICK to Back Up
                </Button>
                <div className="mt-4">
                  Alternatively, connect the MetaMask extension to significantly increase your security
                  <Button
                    className="ml-2"
                    variant="gray"
                    size="small"
                    onClick={handleConnectMetaMask}
                  >
                    Connect metamask
                  </Button> 
                </div>
                 
            </div>
        )}


        

        <div className="pt-4">
          {direction == "withdraw" && (
            <Button
              variant="yellow"
              size="large"
              // onClick={initiateWithdraw}
              className="w-full"
            >
              Withdraw
            </Button>
          )}

          {(direction == "deposit" && selectedWallet.isMetaMask) && (            
            <Button
              variant="yellow"
              size="large"    
              disabled={isWaitingConfirmation || isApprovalPending || isDepositPending}
              loading={isWaitingConfirmation || isApprovalPending || isDepositPending} 
              link={pendingTxHash != "" 
                ? selectedMarginCurrency.network == "Ethereum"
                  ? "https://etherscan.io/tx/"+pendingTxHash
                  : selectedMarginCurrency.network == "Polygon" 
                    ? "https://polygonscan.com/tx/"+pendingTxHash
                    : selectedMarginCurrency.network == "Arbitrum" 
                      ? "https://arbiscan.io/tx/"+pendingTxHash
                      : selectedMarginCurrency.network == "Base" 
                        ? "https://basescan.org/tx/"+pendingTxHash
                        : "https://snowtrace.io/tx/"+pendingTxHash
                : ""
              }       
              //onClick={initiateDeposit}
              className="w-full"
            >
              {isWaitingConfirmation
              ? "Confirm in External Wallet"
              : isApprovalPending 
                ? "Approval Pending" 
                : isDepositPending 
                  ? "Deposit Pending"
                  : "Deposit"
              }
            </Button>
          )}
        </div>



      </form>
        <Modal visible={isWithdrawalConfirmDialogOpen} onClose={handleCloseWithdrawalConfirmDialog} freeToClose>
            <WithdrawalConfirmDialog 
                open={isWithdrawalConfirmDialogOpen}
                onClose={handleCloseWithdrawalConfirmDialog}
                onConfirm={handleConfirmWithdrawalConfirmDialog}
                marginCurrency={selectedMarginCurrency}
                amount={formatAmount(Number(amount)*1e18, selectedMarginCurrency.decimals+4)}
                feeWithdrawal={formatGasFee(calcGasFee().token_fee, selectedMarginCurrency.decimals+4)}
                netAmount={formatAmount((Number(amount)*1e18-Number(calcGasFee().token_fee)*1e18), 6)}
                destinationAddress={destinationAddress}                
            />
        </Modal>
    </div>
  );
};

const formatWalletAmount = (amount: number, amount_dec: number, token_decimals: number) => {
    // Convert amount to a base that aligns with token_decimals
    const adjustedAmount = (amount / Math.pow(10, 18)) * Math.pow(10, 18 - token_decimals);
    // Calculate the scaling factor based on amount_dec
    const scale = Math.pow(10, amount_dec);
    // Scale up, apply floor to round down, and scale back down
    const roundedAmount = Math.floor(adjustedAmount * scale) / scale;
    // Format the number as a string with fixed decimal places
    return roundedAmount.toFixed(amount_dec);
};

const formatAmount = (amount: number, decimals: number): string => {
  return (Number(amount) / 1e18).toFixed(decimals);
};

const formatGasPrice = (price: number): string => {
  return price.toFixed(2);
};

// const calcGasFee = (gas_price: number, gas: number, margin_currency: MarginCurrency, mark_price: number): string => {
//   return (gas_price*gas*1e9*mark_price).toFixed(0);
// };

const formatGasFee = (gas_fee: number, decimals: number): string => {
  //console.log(`[formatGasFee] gas_fee=${gas_fee} decimals=${decimals}`);
  return (gas_fee).toFixed(decimals);
};

export default DepositWithdrawDialog;
